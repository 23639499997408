import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { FaWhatsapp } from 'react-icons/fa';
import { MdColorLens } from 'react-icons/md';
import { TiDownloadOutline } from 'react-icons/ti';
import './WhatsAppQRCodeGenerator.css';

const WhatsAppQRCodeGenerator = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [qrColor, setQRColor] = useState('#000000');
  const [logo, setLogo] = useState('');
  const [qrShape, setQRShape] = useState('square');

  const generateQRCode = () => {
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(logo)}`;
    return whatsappLink;
  };

  const downloadQRCode = () => {
    if (phoneNumber.trim() !== '') {
      const canvas = document.getElementById('qrcode');
      const qrCodeURL = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      const link = document.createElement('a');
      link.href = qrCodeURL;
      link.download = 'WhatsAppQRCode.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert('Please enter your WhatsApp number.');
    }
  };

  return (
    <>
    <div className="container">
      
      <div className="content">
        <h1 className="title">WhatsApp QR Code Generator </h1>
        <p className="subtitle">
This tool lets you make a unique QR code for your WhatsApp number. You can pick colors, add images, and include a custom message to start chats with style.</p>
        <div className="form-group">
          <label htmlFor="phoneNumber">WhatsApp Number *</label>
          <input
            type="text"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter your WhatsApp number"
            required // Make WhatsApp number required
          />
        </div>
        <div className="form-group">
          <label htmlFor="welcomeMessage">Welcome Message (Optional)</label>
          <textarea
            id="welcomeMessage"
            value={welcomeMessage}
            onChange={(e) => setWelcomeMessage(e.target.value)}
            placeholder="Enter your welcome message"
          />
        </div>
        <div className="form-group">
          <label htmlFor="qrColor">Pick your color for QR Code (Optional) <MdColorLens /></label>
          <input
            type="color"
            id="qrColor"
            value={qrColor}
            onChange={(e) => setQRColor(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="logo">Logo URL (optional)</label>
          <input
            type="text"
            id="logo"
            value={logo}
            onChange={(e) => setLogo(e.target.value)}
            placeholder="Enter URL of your logo (optional)"
          />
        </div>
      </div>
      <div className="spacer"></div> {/* Add a spacer */}
      <div className="qr-code-container">
        <QRCode
          id="qrcode"
          value={generateQRCode()}
          size={256}
          bgColor={"#ffffff"}
          fgColor={qrColor}
          level={"H"}
          includeMargin={true}
          imageSettings={
            logo ? {
              src: logo,
              x: null,
              y: null,
              height: 50,
              width: 50,
              excavate: true,
            } : null
          }
          style={{ shapeRendering: qrShape }}
          className="qr-code"
        />
        <div className="button-group">
          <button className="generate-btn" onClick={downloadQRCode}><TiDownloadOutline /> Download QR Code</button>
        </div>
      </div>
    </div>
     
    
     </>
  );
};

export default WhatsAppQRCodeGenerator;
